import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  client: {
    NEXT_PUBLIC_APP_URL: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string().min(1),
    NEXT_PUBLIC_MIXPANEL: z.string().min(1),
  },
  server: {
    DATABASE_URL: z.string().min(1),
    PRISMIC_ACCESS_TOKEN: z.string().min(1),
    NODE_ENV: z.enum(["development", "test", "production"]),
    GOOGLE_MAPS_API_KEY: z.string().min(1),
    POSTMARK_API_KEY: z.string().min(1),
  },
  runtimeEnv: {
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    DATABASE_URL: process.env.DATABASE_URL,
    PRISMIC_ACCESS_TOKEN: process.env.PRISMIC_ACCESS_TOKEN,
    NODE_ENV: process.env.NODE_ENV,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    POSTMARK_API_KEY: process.env.POSTMARK_API_KEY,
    NEXT_PUBLIC_MIXPANEL: process.env.NEXT_PUBLIC_MIXPANEL,
  },
})
