"use client"

import { useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"

import {
  GetAllDealersReturnType,
  GetSingleDealerReturnType,
} from "@/lib/server-api/dealers-types"
import useOnScreen from "@/hooks/use-on-screen"
import { Skeleton } from "@/components/ui/skeleton"

import { DealerSelectVariants } from "../DealerSelect"

const DealerAllMap = dynamic(() => import("./DealerMapAll"), {
  loading: () => <Skeleton className="h-[300px] md:h-[500px]" />,
})

interface PropsIf {
  allDealers: GetAllDealersReturnType[]
  selectedDealer?: GetAllDealersReturnType | GetSingleDealerReturnType | null
  setSelectedDealer?: React.Dispatch<
    React.SetStateAction<
      GetAllDealersReturnType | GetSingleDealerReturnType | null
    >
  >
  variant?: DealerSelectVariants
}

export default function DealerMapAllLazy({
  allDealers,
  selectedDealer,
  setSelectedDealer,
  variant = "default",
}: PropsIf) {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setShow(true)
    }
  }, [isVisible])

  return (
    <div ref={ref}>
      {(show || variant === "find_a_pro") && (
        <DealerAllMap
          selectedDealer={selectedDealer}
          setSelectedDealer={setSelectedDealer}
          allDealers={allDealers}
          variant={variant}
        />
      )}
    </div>
  )
}
