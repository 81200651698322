import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-sm border w-fit border-carbon-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-carbon-950 focus:ring-offset-2 dark:border-carbon-800 dark:focus:ring-carbon-300",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-carbon-500 text-carbon-50 hover:bg-carbon-900/80 dark:bg-carbon-50 dark:text-carbon-900 dark:hover:bg-carbon-50/80",
        secondary:
          "border-transparent bg-carbon-100 text-carbon-900 hover:bg-carbon-100/80 dark:bg-carbon-800 dark:text-carbon-50 dark:hover:bg-carbon-800/80",
        flame:
          "border-transparent bg-flame-500 text-carbon-50 hover:bg-flame-500/80 dark:bg-flame-900 dark:hover:bg-flame-900/80",
        "flame-outline":
          "text-flame-500 dark:text-flame-50 border-flame-500",
        outline: "text-carbon-950 dark:text-carbon-50",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
