import { RefObject, useEffect, useMemo, useState } from "react"

export default function useOnScreen(
  ref: RefObject<HTMLElement>,
  rootMargin?: string
) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible((prev) => {
          if (prev) return true
          return entry.isIntersecting
        })
      },
      {
        rootMargin: rootMargin ?? "400px 0px 0px 0px",
      }
    )

    const currentElement = ref?.current

    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) observer.unobserve(currentElement)
    }
  }, [])

  return isVisible
}
