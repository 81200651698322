"use client"

import { useState } from "react"
import { useRouter } from "next/navigation"
import { Loader2 } from "lucide-react"

import { clearCurrnetDealerId } from "@/lib/cookies.client"

import { Button } from "../ui/button"

export default function ButtonChangePro() {
  const router = useRouter()

  function handleClick() {
    setLoading(true)
    clearCurrnetDealerId()
    router.refresh()
  }

  const [loading, setLoading] = useState(false)

  return (
    <Button
      variant={"secondary"}
      className="flex flex-row flex-wrap gap-2 whitespace-nowrap"
      onClick={handleClick}
    >
      Change Pro {loading && <Loader2 className="size-4 animate-spin" />}
    </Button>
  )
}
