import { HTMLProps } from "react"

import { cn } from "@/lib/utils"

import { Badge } from "./ui/badge"

interface PropsIf {
  children?: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
}

export default function TextBlock({ children, className }: PropsIf) {
  return <div className={cn("flex flex-col gap-1", className)}>{children}</div>
}

export function TextBlockHeading({
  children,
  className,
  level = 2,
}: {
  children: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
  level?: 1 | 2 | 3 | 4 | 5 | 6
}) {
  const Heading = `h${level}` as keyof JSX.IntrinsicElements
  return (
    <Heading
      className={cn(
        "text-3xl md:text-4xl font-heading text-carbon-500",
        className
      )}
    >
      {children}
    </Heading>
  )
}

export function TextBlockCopy({
  children,
  className,
}: {
  children: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
}) {
  return (
    <p className={cn("text-base font-copy text-carbon-400", className)}>
      {children}
    </p>
  )
}

export function TextBlockEyebrow({
  children,
  className,
}: {
  children: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
}) {
  return (
    <Badge variant={"flame-outline"} className={cn("mb-3", className)}>
      {children}
    </Badge>
  )
}

export function TextBlockButtons({
  children,
  className,
}: {
  children: React.ReactNode
  className?: HTMLProps<HTMLElement>["className"]
}) {
  return (
    <div className={cn("flex flex-row flex-wrap gap-2 mt-3", className)}>
      {children}
    </div>
  )
}
