"use client"

import { useRef } from "react"
import dynamic from "next/dynamic"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"
import useOnScreen from "@/hooks/use-on-screen"

const ConsultationForm = dynamic(() => import("./ConsultationForm"))

export type ConsultationFormPropsType = {
  dealerOverride?: GetSingleDealerReturnType
}

export default function ConsultationFormLazy(props: ConsultationFormPropsType) {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  return <div ref={ref}>{isVisible && <ConsultationForm {...props} />}</div>
}
