"use client"

import Link from "next/link"
import { usePathname } from "next/navigation"
import { ArrowRight, Mail, MapPin, Phone, Star } from "lucide-react"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"
import { formatPhoneNumber } from "@/lib/utils"

import { getDealerHours } from "../../lib/utils"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card"

interface PropsIf {
  dealer: GetSingleDealerReturnType
}

export default function DealerCard({ dealer }: PropsIf) {
  const getAddress = () => {
    const { address, city, state, zip } = dealer
    return `${address}, ${city}, ${state} ${zip}`
  }

  const pathname = usePathname()

  const days = getDealerHours(dealer)
  const now = new Date()
  const today = days[now.getDay()]
  return (
    <Card>
      <CardHeader>
        {dealer.dealer_type !== "odp" && (
          <Badge className="mb-2" variant={"default"}>
            Featured
          </Badge>
        )}
        <CardTitle className="text-xl">{dealer.name}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-3 text-sm text-carbon-400">
        <p>{`Today's Hours: ${today}`}</p>
        <a
          className="w-fit"
          href={`https://www.google.com/maps/place/${encodeURIComponent(
            getAddress()
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <ul>
            <li>{dealer.address}</li>
            <li>
              {dealer.city}, {dealer.state} {dealer.zip}
            </li>
          </ul>
        </a>
        <a className="w-fit" href={`tel:${dealer.phone}`}>
          {formatPhoneNumber(dealer.phone)}
        </a>
        <a
          className="w-fit"
          href={`mailto:${dealer.sales_email}?cc=confirmation@onedaydoorsandclosets.com`}
        >
          {dealer.sales_email}
        </a>
        {dealer._count.dealer_reviews > 0 && (
          <p className="flex flex-row flex-wrap items-center">
            4.9{" "}
            <Star className="ml-1 mr-2 size-4 text-amber-500 fill-amber-500" />{" "}
            ({dealer._count.dealer_reviews})
          </p>
        )}
      </CardContent>
      <CardFooter className="justify-between flex-wrap gap-2 flex-row">
        <div className="flex flex-row gap-2">
          <Button variant={"secondary"} size={"icon"} asChild>
            <a
              href={`mailto:${dealer.sales_email}?cc=confirmation@onedaydoorsandclosets.com`}
            >
              <Mail className="size-4" />
            </a>
          </Button>
          <Button variant={"secondary"} size={"icon"} asChild>
            <a href={`tel:${dealer.phone}`}>
              <Phone className="size-4" />
            </a>
          </Button>
          <Button variant={"secondary"} size={"icon"} asChild>
            <a
              href={`https://www.google.com/maps/place/${encodeURIComponent(
                getAddress()
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <MapPin className="size-4" />
            </a>
          </Button>
        </div>

        {!pathname.startsWith("/professionals/") && (
          <Button
            variant={"secondary"}
            size={"default"}
            className="flex flex-row flex-wrap gap-2"
            asChild
          >
            <Link href={`/professionals/${dealer.geo_area}`}>
              View Pro
              <ArrowRight className="size-4" />
            </Link>
          </Button>
        )}
      </CardFooter>
    </Card>
  )
}
