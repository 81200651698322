"use client"

import { useRef, useState } from "react"
import dynamic from "next/dynamic"

import {
  GetAllDealersReturnType,
  GetSingleDealerReturnType,
} from "@/lib/server-api/dealers-types"
import { cn } from "@/lib/utils"
import useOnScreen from "@/hooks/use-on-screen"
import TextBlock, {
  TextBlockEyebrow,
  TextBlockHeading,
} from "@/components/TextBlock"

import DealerMapAllLazy from "./Map/DealerMapAllLazy"

const DealerList = dynamic(() => import("./DealerList"))

export type DealerSelectVariants = "default" | "find_a_pro"

interface PropsIf {
  allDealers: GetAllDealersReturnType[]
  popup: boolean
  variant?: DealerSelectVariants
}

export default function DealerSelect({
  allDealers,
  popup,
  variant = "default",
}: PropsIf) {
  const [selectedDealer, setSelectedDealer] = useState<
    GetAllDealersReturnType | GetSingleDealerReturnType | null
  >(null)
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  return (
    <div className={cn(popup && "overflow-auto md:p-0 p-1 max-h-[85vh] md:max-h-[90vh]")}>
      {popup && (
        <TextBlock>
          <TextBlockEyebrow>Our Pros</TextBlockEyebrow>
          <TextBlockHeading level={4}>Find a Pro</TextBlockHeading>
        </TextBlock>
      )}
      <div
        className={cn(
          "grid grid-cols-1 gap-10 ",
          variant == "default" && "md:grid-cols-2"
        )}
        ref={ref}
      >
        <DealerMapAllLazy
          selectedDealer={selectedDealer}
          setSelectedDealer={setSelectedDealer}
          allDealers={allDealers}
          variant={variant}
        />
        {(isVisible || variant === "find_a_pro") && (
          <DealerList
            selectedDealer={selectedDealer}
            setSelectedDealer={setSelectedDealer}
            allDealers={allDealers}
            variant={variant}
          />
        )}
      </div>
    </div>
  )
}
