"use client"

import { useRef } from "react"
import dynamic from "next/dynamic"

import useOnScreen from "@/hooks/use-on-screen"

const ProForm = dynamic(() => import("./ProForm"))

export default function ProFormLazy() {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  return <div ref={ref}>{isVisible && <ProForm />}</div>
}
